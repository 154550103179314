import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Image, Modal } from "react-bootstrap";

const ProductPopUp = (props) => {
  return (
    <Modal {...props} centered={true} className="justify-content-center text-center">
      <Col className="text-end p-3">
        <FontAwesomeIcon icon={faClose} color="red" onClick={props.onHide} />
      </Col>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center text-center">
        <Image
          src={require("../../images/bazar-1.png")}
          style={{ width: "80%" }}
          className="mb-4"
        />
        {/* <Image
          src={
            props.type === 0
              ? require("../../images/success-select.png")
              : require("../../images/claim-near.png")
          }
          style={{ width: "80%" }}
          className="mb-5"
        /> */}
        <h4 style={{ color: "red", width: "80%" }} className="justify-content-center align-items-center mb-2">
          {props.type === 0 ? "Maaf stok produk sedang kosong" : "Datang ke toko atau bazzar terdekat"}
        </h4>

        <Button className="btn-activation w-100" onClick={props.onHide}>
          Oke
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ProductPopUp;
